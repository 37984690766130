const schemaRegionalisedData = {
  us: {
    address: {
      '@type': 'PostalAddress',
      '@id': 'https://www.deputy.com/#usAddress',
      addressCountry: 'US',
      postalCode: '94105',
      streetAddress: '149 New Montgomery St, 4th Floor, Suite 563',
      addressRegion: 'CA',
      addressLocality: 'San Francisco, United States',
    },
    telephone: '+1 855 633 7889',
    geo: {
      '@type': 'GeoCoordinates',
      addressCountry: 'US',
      latitude: 37.7897312,
      longitude: -122.401906,
    },
    urlLocation:
      'https://www.google.com/maps/place/Deputy/@37.7897312,-122.401906,17z/data=!3m2!4b1!5s0x80857f993df48df3:0xa4e0f3d2edcd6ae2!4m5!3m4!1s0x80858135b08a362b:0x618749d040e029db!8m2!3d37.7897312!4d-122.401906',
  },
  au: {
    address: {
      '@type': 'PostalAddress',
      '@id': 'https://www.deputy.com/#auAddress',
      addressCountry: 'AU',
      postalCode: '2000',
      streetAddress: 'Level 13, 580 George Street',
      addressRegion: 'NSW',
      addressLocality: 'Sydney, Australia',
    },
    telephone: '+61 1300 337 889',
    geo: {
      '@type': 'GeoCoordinates',
      addressCountry: 'AU',
      latitude: -33.8747966,
      longitude: 151.2071479,
    },
    urlLocation:
      'https://www.google.com/maps/place/Deputy/@-33.8747921,151.2026632,17z/data=!3m2!4b1!5s0x6b12ae5ea31fed91:0x22cfd9f8c06bf5bd!4m5!3m4!1s0x6b12b1c5b68347eb:0x8105d023ed6107f2!8m2!3d-33.8747966!4d151.2071479',
  },
  gb: {
    address: {
      '@type': 'PostalAddress',
      '@id': 'https://www.deputy.com/#gbAddress',
      addressCountry: 'GB',
      postalCode: 'EC2A 3EJ',
      streetAddress: '21-33 Great Eastern Street',
      addressLocality: 'London, United Kingdom',
    },
    telephone: '+44 2031501149',
    geo: {
      '@type': 'GeoCoordinates',
      addressCountry: 'GB',
      latitude: 51.5236645,
      longitude: -0.0795206,
    },
    urlLocation:
      'https://www.google.com/maps/place/Deputy/@51.5236645,-0.0795206,17z/data=!4m5!3m4!1s0x48760506dbde5309:0x98247d4cc450bd52!8m2!3d51.5236645!4d-0.0795206',
  },
};

export default function getRegionalizedData(value, region = 'us') {
  region = region === 'uk' ? 'gb' : region;
  return schemaRegionalisedData[region.toLowerCase()][value];
}
