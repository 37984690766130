export default {
  getSchema(review) {
    return review
      ? {
          '@type': 'Review',
          '@id': 'https://www.deputy.com/#review',
          itemReviewed: { '@id': 'https://www.deputy.com/#business' },
          author: {
            '@type': 'Organization',
            name: 'Capterra',
            sameAs: ['https://www.capterra.com/p/147589/Deputy/'],
          },
          name: 'Deputy',
          inLanguage: 'en',
          datePublished: '2019-07-27',
          description:
            'Deputy is seriously like the sheriff that came in and cleaned up our employee scheduling nightmare!',
          reviewRating: {
            '@type': 'Rating',
            ratingValue: '4.8',
          },
          reviewBody:
            "We had employees staying clocked on after shifts milking the clock. We didn't notice it until it was time to run payroll. Now this tells me whenever someone stays on more than 5 minutes past their shift. Or clocks in more than 5 minutes early. 6 stores, 3 shifts a day, it really adds up quickly!",
        }
      : '';
  },
};
