
import schema from 'mixins/schema';
import getPage from 'mixins/getPage';
import headerFooter from 'mixins/headerFooter';
import PageScaffolder from '~/components/page-scaffolder';
import getLayout from '~/utils/getLayout';

export default {
  components: {
    PageScaffolder,
  },
  middleware: ['storyblokVersion'],
  mixins: [getPage('all'), headerFooter, schema],
  data() {
    return {
      story: this.$store.state.story,
      region: this.$store.state.region,
      pageMeta: {},
    };
  },
  layout: getLayout,
  head() {
    return this.pageMeta;
  },
};
