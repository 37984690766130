// import { getRegionFromPath } from './urlHelpers';

export default function getLayout(nuxtContext) {
  const isWidget = /^(\/au|\/gb|\/us)?\/?widget/.test(
    nuxtContext.route.fullPath
  );
  const isApi = /^(\/au|\/gb|\/us)?\/?template\/blank-api-simple/.test(
    nuxtContext.route.fullPath
  );
  const isLogin = /^(\/us)?\/?login\/welcome-carousel/.test(
    nuxtContext.route.fullPath
  );

  if (isWidget) {
    return 'default-widget';
  } else if (isApi) {
    return 'default-logo-title-home-link';
  } else if (isLogin) {
    return 'default-login';
  }
  // const region = getRegionFromPath(nuxtContext.route.fullPath);
  // if (region === 'au') {
  //   return 'default-au';
  // } else if (region === 'gb') {
  //   return 'default-gb';
  // }
  return 'default';
}
