import richtext from '~/utils/richtext';

export default {
  faqSchema(story, region) {
    const faqSchema = {
      '@context': 'https://schema.org/',
      '@type': 'FAQPage',
      keywords: story.content.seo.seoKeywords,
      mainEntity: [],
    };

    const faqCollection = story.content.content.find(
      blok => blok.component === 'cms-faq-collection'
    );

    faqSchema.mainEntity = faqCollection.faqs.reduce((faqs, faq) => {
      faqs.push({
        '@type': 'Question',
        name: faq.question,
        inLanguage: region ? 'en-' + region.toUpperCase() : 'en',
        answerCount: 1,
        acceptedAnswer: {
          '@type': 'Answer',
          inLanguage: region ? 'en-' + region.toUpperCase() : 'en',
          text: richtext(faq.answer, true),
        },
      });
      return faqs;
    }, []);

    return faqSchema;
  },

  getSchema(story, region) {
    let hasFAQs = false;

    if (Array.isArray(story.content.content)) {
      hasFAQs = story.content.content.some(
        blok => blok.component === 'cms-faq-collection'
      );
    }

    if (!hasFAQs) {
      return '';
    }

    return this.faqSchema(story, region);
  },
};
