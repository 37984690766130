import {
  appendStoryblokReleaseID,
  getStoryblokRequestPath,
} from '~/utils/storyblokHelpers';
import getResolutions from 'mixins/resolutions';
import { getPageLayout } from 'mixins/pageLayout';
import { getSpacing, spacingTopGetClass } from 'mixins/spacingLayout';

async function getStandardPage(
  { app, version, route, isDev, store },
  resolutionsKey
) {
  let story;
  let storyParams = {
    version,
    resolve_relations: getResolutions(resolutionsKey, store.state.region),
  };
  const requestPath = getStoryblokRequestPath(
    route.path,
    store.state.region,
    isDev
  );
  const storyUrl = `cdn/stories${requestPath}`;

  storyParams = appendStoryblokReleaseID(storyUrl, storyParams);

  try {
    story = await app.$storyapi.get(storyUrl, storyParams);
  } catch (error) {
    console.error('There was an error fetching the page:', error);
    console.error('fullPath: ', route.fullPath);
    console.error('region: ', store.state.region);
    console.error('isDev: ', isDev);
    console.error('isPreview: ', process.env.isPreview);
    console.error('storyUrl: ', storyUrl);
    console.error('storyParams: ', storyParams);
    throw error;
  }

  getPageLayout(story, store);

  await store.commit('saveStory', story.data.story);

  const spacingTop = await getSpacing(story.data.story.content);
  if (spacingTop) {
    await store.commit('setPaddingTop', spacingTopGetClass(spacingTop));
  }

  return {
    story: store.state.story,
    region: store.state.region,
  };
}

const getPage = resolutionsKey => ({
  async asyncData(context) {
    return await getStandardPage(context, resolutionsKey);
  },
});

export default getPage;
