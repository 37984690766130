export default {
  all_slugs: [],
  slugArray: [],

  // Parse the alternates urls to the corresponding URL for schema
  getAlternatesUrls(slugArray, index) {
    const urls = [];
    const regionalisedLinks = [];
    const rawLink = slugArray[0][index].rawLink.replace(/\/$/, '');
    urls.push(rawLink);
    slugArray.forEach(value => {
      if (value[index]?.hasOwnProperty('regionalisedLink')) {
        const regionalisedLink = value[index].regionalisedLink.replace(
          /\/$/,
          ''
        );
        // we are doing this extra check here to avoid case where the global url is added twice
        // because storyblok return the global slug by prepending us/ which is trimmed
        // we also need to make sure that the regional link hasn't been added yet to avoid any duplicate
        if (
          regionalisedLink !== rawLink &&
          !regionalisedLinks.includes(regionalisedLink)
        ) {
          regionalisedLinks.push(regionalisedLink);
          urls.push({
            '@type': 'LinkRole',
            url: regionalisedLink,
            inLanguage: value[index].inLanguage,
            linkRelationship: 'alternate',
          });
        }
      }
    });
    return urls;
  },

  // Get the region from the slug and set the appropriated language (for Schema SEO - ISO 639)
  getRegion(slug) {
    const regexFound = /^(au|gb)\//.exec(slug);
    if (regexFound) {
      return {
        region: regexFound[1] + '/',
        language: 'en-' + regexFound[1].toUpperCase(),
      };
    }
    return {
      region: '',
      language: 'en',
    };
  },

  // remove any regions from the link
  getRawLink(link) {
    return link.replace(/\/*(au|gb|us)\//, '/');
  },

  // Get any alternates links and add them to the slug array
  checkAlternates(alternates) {
    if (alternates.length !== 0) {
      alternates.forEach(value => {
        // check if the url contains only au or gb
        // if it contains us/ we don't want to add it as it's the global url
        if (/^(au|gb)\//.exec(value.full_slug)) {
          this.all_slugs.push(value.full_slug);
        }
      });
    }
  },

  // Remove dash from string
  removeDash(str) {
    return str.replace('-', ' ');
  },

  formatSlug(story) {
    const breadcrumbs = [];
    // add request slug to array (we remove the global slug region)
    this.all_slugs.push(
      story.full_slug.replace(/^us\//, '').replace(/\/$/, '')
    );
    // check for alternates links with different regions
    this.checkAlternates(story.alternates);
    // Deputy domains
    const domain = 'https://www.deputy.com/';

    this.all_slugs.forEach(value => {
      const slugArray = value.split('/');

      const infoUrl = this.getRegion(value);
      // Home will always be the first element in the breadcrumb
      const slugs = [
        {
          text: 'home',
          regionalisedLink: domain + infoUrl.region,
          rawLink: this.getRawLink(domain + infoUrl.region),
          inLanguage: infoUrl.language,
        },
      ];

      // We need to loop through the array and create the links base on the slugs
      slugArray.map((value, index) => {
        if (value !== '') {
          const text = this.removeDash(value);
          const link = slugArray.slice(0, index + 1).join('/');
          // if the link part is not a region we add it to the array
          if (!/^(au|gb|us)$/.exec(link)) {
            slugs.push({
              text: text,
              regionalisedLink: domain + link,
              rawLink: this.getRawLink(domain + link),
              inLanguage: infoUrl.language,
            });
          }
        }
      });
      breadcrumbs.push(slugs);
    });
    return breadcrumbs;
  },

  getSchema(story) {
    this.all_slugs = [];
    this.slugArray = [];
    const breadcrumbSchema = {
      '@type': 'BreadcrumbList',
      '@id':
        'https://www.deputy.com/' +
        story.full_slug.replace(/^us\//, '') +
        '#breadcrumb',
      itemListElement: [],
    };

    const fullSlug = this.formatSlug(story);

    breadcrumbSchema.itemListElement = fullSlug[0].reduce(
      (slugs, slug, index) => {
        slugs.push({
          '@type': 'ListItem',
          position: index + 1,
          item: {
            '@type': 'WebPage',
            '@id': slug.rawLink + '/#item-' + (index + 1),
            url: this.getAlternatesUrls(fullSlug, index),
            inLanguage: 'en',
            name: slug.text,
          },
        });
        return slugs;
      },
      []
    );

    return breadcrumbSchema;
  },
};
