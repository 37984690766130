export default {
  data() {
    return {
      headerClass: 'default-header',
      footerClass: 'default-footer',
    };
  },
  created() {
    this.headerClass =
      this.$store.state.story.content.header || this.headerClass;
    this.footerClass =
      this.$store.state.story.content.footer || this.footerClass;

    Object.assign(this.$data.pageMeta, {
      bodyAttrs: {
        class: `${this.headerClass} ${this.footerClass}`,
      },
    });
  },
};
