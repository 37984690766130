export default {
  getSchema() {
    return {
      '@type': 'WebSite',
      '@id': 'https://www.deputy.com/#website',
      inLanguage: 'en',
      keywords: [
        'workforce management',
        'scheduling software',
        'time tracking software',
        'staff management software',
      ],
      url: [
        'https://www.deputy.com/',
        {
          '@type': 'LinkRole',
          url: 'https://www.deputy.com/au',
          inLanguage: 'en-AU',
          linkRelationship: 'alternate',
        },
        {
          '@type': 'LinkRole',
          url: 'https://www.deputy.com/gb',
          inLanguage: 'en-GB',
          linkRelationship: 'alternate',
        },
      ],
      name: 'Deputy',
      publisher: { '@id': 'https://www.deputy.com/#organization' },
    };
  },
};
