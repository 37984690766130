import getRegionalizedData from './schemaRegionalisedData';

export default {
  getSchema(region) {
    return {
      '@type': 'Organization',
      '@id': 'https://www.deputy.com/#organization',
      name: 'Deputy',
      logo: 'https://www.deputy.com/g-assets/deputy-logo.png',
      legalName: 'Deputechnologies Pty Ltd',
      foundingDate: '2008',
      description:
        'Deputy is a simple employee scheduling app & time tracking software for businesses of any size. Start your free trial today!',
      url: 'https://www.deputy.com/',
      alternateName: [
        'Deputy Scheduling Software',
        'Deputy Rostering Software',
        'Deputy App',
      ],
      sameAs: [
        'https://www.linkedin.com/company/deputyapp',
        'https://www.facebook.com/deputyapp',
        'https://twitter.com/deputyapp',
        'https://www.youtube.com/user/experiencedeputy',
        'https://www.capterra.com/p/180615/Deputy/',
      ],
      location: getRegionalizedData('address', region),
      numberOfEmployees: '300',
    };
  },
};
