export default {
  getSchema(review) {
    return review
      ? {
          '@context': 'https://schema.org/',
          '@type': 'EmployerAggregateRating',
          author: {
            '@type': 'Organization',
            name: 'Glassdoor',
            sameAs: [
              'https://www.glassdoor.com.au/Overview/Working-at-Deputy-EI_IE946335.11,17.htm',
            ],
          },
          itemReviewed: {
            '@id': 'https://www.deputy.com/#organization',
            name: 'Deputy',
          },
          ratingValue: '3.8',
          bestRating: '5',
          worstRating: '2',
          ratingCount: '56',
        }
      : '';
  },
};
