/**
 * Image dimension sizes based on this article:
 * https://www.brandwatch.com/blog/social-media-image-sizes-guide/
 *
 * @param {Object} image Storyblok image field object
 * @return {Array}
 */
export default image => {
  const imageBase = 'https://img2.storyblok.com';
  const facebook = '1200x630';
  const twitter = '1024x512';
  const defaultDeputySocialImage =
    'https://www.deputy.com/g-assets/deputy-social-image.png';
  let facebookImage;
  let twitterImage;

  if (image === 'default') {
    facebookImage = defaultDeputySocialImage;
    twitterImage = defaultDeputySocialImage;
  } else {
    facebookImage = `${imageBase}/${facebook}/${image.imageS3Key}`;
    twitterImage = `${imageBase}/${twitter}/${image.imageS3Key}`;
  }

  return [
    {
      hid: 'fb_image',
      property: 'og:image',
      content: facebookImage,
    },
    {
      hid: 'fb_secure_image',
      property: 'og:image:secure_url',
      content: facebookImage,
    },
    {
      hid: 'fb_image_width',
      property: 'og:image:width',
      content: '1200',
    },
    {
      hid: 'fb_image_height',
      property: 'og:image:height',
      content: '630',
    },
    {
      hid: 'twitter_card',
      name: 'twitter:card',
      content: 'summary',
    },
    {
      hid: 'twitter_image',
      name: 'twitter:image',
      content: twitterImage,
    },
  ];
};
