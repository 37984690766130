export function getSpacing(storyContent) {
  // get the Spacing Top from the page Layout
  if (
    typeof storyContent !== 'undefined' &&
    ((typeof storyContent.spacing_top !== 'undefined' &&
      storyContent.spacing_top !== '') ||
      (typeof storyContent.spacing_top_mobile !== 'undefined' &&
        storyContent.spacing_top_mobile !== ''))
  ) {
    return {
      spacing_top: storyContent.spacing_top,
      spacing_top_mobile: storyContent.spacing_top_mobile,
    };
  }
  return false;
}

export function spacingTopGetClass(spacingTopOptions) {
  const spacingTopClass = [];
  if (spacingTopOptions.spacing_top_mobile) {
    spacingTopClass.push(`pt-${spacingTopOptions.spacing_top_mobile}`);
  }

  if (
    spacingTopOptions.spacing_top &&
    spacingTopOptions.spacing_top_mobile !== spacingTopOptions.spacing_top
  ) {
    const className = spacingTopClass.length > 0 ? 'pt-lg-' : 'pt-';
    spacingTopClass.push(`${className}${spacingTopOptions.spacing_top}`);
  }
  return spacingTopClass;
}
