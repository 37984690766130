import richtext from '~/utils/richtext';
import marked from 'marked';

function getTags(post) {
  const tags = post.content.features_tag.concat(
    post.content.industries_tag,
    post.content.types_tag
  );

  return tags.map(tag => tag.name);
}

function postDescription(post) {
  const content = post.content.content[0].content;
  if (typeof content === 'string') {
    return (
      marked(content)
        .replace(/<[^>]*>?/gm, '')
        .substring(0, 199) + '...'
    );
  }
  return (
    richtext(content, true)
      .replace(/<[^>]*>?/gm, '')
      .substring(0, 199) + '...'
  );
}

export default {
  getSchema(post) {
    return {
      '@context': 'http://schema.org',
      '@type': 'BlogPosting',
      '@id':
        'https://www.deputy.com/' +
        post.full_slug.replace('us/', '') +
        '/#blog-post',
      image: [post.content.thumbnail.imagePublicUrl],
      url: 'https://www.deputy.com/' + post.full_slug.replace('us/', ''),
      dateCreated: post.first_published_at,
      datePublished: post.first_published_at,
      dateModified: post.published_at,
      headline: post.content.headline,
      name: post.content.headline,
      description: postDescription(post),
      thumbnailUrl: post.content.thumbnail.imagePublicUrl,
      identifier: post.id,
      keywords: getTags(post),
      author: {
        '@type': 'Person',
        name: post.content.author.content.name,
        url: 'https://www.deputy.com/blog?author=' + post.content.author.slug,
        image: {
          '@type': 'ImageObject',
          '@id':
            'https://www.deputy.com/author/' +
            post.content.author.slug +
            '/#author-image',
          url: post.content.author.content.image.imagePublicUrl,
          caption: post.content.author.content.name,
          description: post.content.author.content.description,
        },
      },
      creator: [post.content.author.name],
      publisher: {
        '@type': 'Organization',
        name: 'Deputy',
        url: 'https://www.deputy.com',
        logo: {
          '@type': 'ImageObject',
          width: 312,
          height: 60,
          url: 'https://www.deputy.com/g-assets/deputy-logo-publisher.png',
        },
      },
      mainEntityOfPage: {
        '@type': 'WebPage',
        '@id':
          'https://www.deputy.com/' +
          post.full_slug.replace('us/', '') +
          '/#webpage',
        url: 'https://www.deputy.com/' + post.full_slug.replace('us/', ''),
      },
      isAccessibleForFree: 'https://schema.org/True',
      isPartOf: {
        '@type': 'WebSite',
        '@id': 'https://www.deputy.com/#website',
        url: 'https://www.deputy.com',
        name: 'Deputy',
      },
    };
  },
};
