import getRegionalizedData from './schemaRegionalisedData';

export default {
  getSchema(review, region) {
    return {
      '@context': 'https://schema.org',
      '@type': 'LocalBusiness',
      '@id': 'https://www.deputy.com/#business',
      name: 'Deputy',
      image: 'https://www.deputy.com/g-assets/deputy-logo.png',
      sameAs: 'https://www.deputy.com',
      priceRange: '$3-5',
      address: getRegionalizedData('address', region),
      telephone: getRegionalizedData('telephone', region),
      geo: getRegionalizedData('geo', region),
      url: getRegionalizedData('urlLocation', region),
      openingHoursSpecification: [
        {
          '@type': 'OpeningHoursSpecification',
          dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
          opens: '09:00',
          closes: '17:00',
        },
      ],
      aggregateRating: review
        ? {
            '@type': 'AggregateRating',
            ratingValue: '4.8',
            bestRating: '5',
            ratingCount: '959',
          }
        : '',
      review: review ? { '@id': 'https://www.deputy.com/#review' } : '',
    };
  },
};
