import faq from './faqPage';
import breadcrumb from './breadcrumb';
import organization from './organization';
import localBusiness from './localBusiness';
import review from './review';
import webpage from './webpage';
import blogPosting from './blogPosting';
import website from './website';
import employerAggregateRating from './employerAggregateRating';
import socialImages from './socialImages';
import { useStoryblokBridge } from '@storyblok/nuxt-2';

export default {
  data() {
    return {
      schema: {
        '@context': 'https://schema.org',
        '@graph': [],
      },
      story: {
        content: {},
      },
    };
  },
  /* istanbul ignore next */
  created() {
    const schemaPageMeta = this.getPageMeta();
    Object.assign(this.$data.pageMeta, schemaPageMeta);
  },
  mounted() {
    /* istanbul ignore next */
    if (window.location.search.includes('_storyblok')) {
      useStoryblokBridge(this.story.id, newStory => (this.story = newStory));
      const storyblokInstance = new window.StoryblokBridge();
      storyblokInstance.on(['published', 'change'], event => {
        // reload page if save or publish is clicked
        if (!event.slugChanged) {
          // Reload the page on save events (publish, save button or autosave)
          try {
            this.$nuxt.$router.go({
              path: this.$nuxt.$router.currentRoute,
              force: true,
            });
          } catch (e) {
            console.log(e);
          }
        }
      });
    }
  },
  methods: {
    getPageMeta() {
      // remove any trailing slash if found
      function removeTrailingSlash(slug) {
        return slug.replace(/\/$/, '');
      }

      // to make sure tests pass as $store can't be accessible
      const storyObject = this.$store ? this.$store.state.story : this.story;
      const regionUrl = this.$store ? this.$store.state.region : this.region;
      // I added a fallback in case the $nuxt context is not available
      const realPath =
        this?.$nuxt?.context.route.path.replace(/^\//, '') ||
        storyObject.full_slug;

      const baseUrl =
        this?.$nuxt?.context.env.baseUrl || 'https://www.deputy.com';
      const showReview =
        'reviewSchema' in (storyObject.content.seo || {})
          ? storyObject.content.seo.reviewSchema
          : false;

      this.schema['@graph'].push(organization.getSchema(regionUrl));
      this.schema['@graph'].push(faq.getSchema(storyObject, regionUrl));
      this.schema['@graph'].push(breadcrumb.getSchema(storyObject));
      // review + aggregate rating can be remove
      this.schema['@graph'].push(
        localBusiness.getSchema(showReview, regionUrl)
      );
      // removed if no rating needs to be shown
      this.schema['@graph'].push(employerAggregateRating.getSchema(showReview));
      this.schema['@graph'].push(review.getSchema(showReview));

      const blogPostRegex = new RegExp('^(au|gb|us)*/?blog/(?!page/\\d+).+');
      if (blogPostRegex.test(realPath)) {
        this.schema['@graph'].push(blogPosting.getSchema(storyObject));
      } else {
        this.schema['@graph'].push(webpage.getSchema(storyObject, regionUrl));
        this.schema['@graph'].push(website.getSchema());
      }

      // KEEP for now in case we need to add it back
      /*
       * return the meta hreflang object to be injected
       */
      // function generateHreflang(slug) {
      //   const regexFound = /^(au|gb)\//.exec(slug);
      //   const realSlug = removeTrailingSlash(
      //     realPath.replace(/^(us|au|gb)\/?/, '')
      //   );
      //   if (regexFound) {
      //     return {
      //       rel: 'alternate',
      //       hreflang: 'en-' + regexFound[1],
      //       href: `${baseUrl}/${regexFound[1]}/${realSlug}`,
      //     };
      //   }
      //   return {
      //     rel: 'alternate',
      //     hreflang: 'x-default',
      //     href: `${baseUrl}/${realSlug}`,
      //   };
      // }

      const meta = [
        {
          hid: 'description',
          name: 'description',
          content: storyObject.content.seo.seoDescription,
        },
        {
          hid: 'fb_locale',
          property: 'og:locale',
          content: `en_${regionUrl ? regionUrl.toUpperCase() : 'US'}`,
        },
        {
          hid: 'fb_title',
          property: 'og:title',
          content: storyObject.content.seo.seoTitle,
        },
        {
          hid: 'fb_desc',
          property: 'og:description',
          content: storyObject.content.seo.seoDescription,
        },
        {
          hid: 'twitter_title',
          property: 'twitter:title',
          content: storyObject.content.seo.seoTitle,
        },
        {
          hid: 'twitter_description',
          property: 'twitter:description',
          content: storyObject.content.seo.seoDescription,
        },
        // KEEP for now in case we need to add it back
        // generateHreflang(realPath),
      ];

      // KEEP for now in case we need to add it back
      // loop through any alternates url of the pages
      // storyObject.alternates.forEach(value => {
      //   meta.push(generateHreflang(value.full_slug));
      // });

      const isDeputyUrl = /deputy.com/.test(baseUrl);

      if (storyObject.content.seo.noindex === true || !isDeputyUrl) {
        meta.push({ hid: 'robots', name: 'robots', content: 'noindex' });
      }

      if (storyObject.content.component === 'blog-post') {
        meta.push(...socialImages(storyObject.content.thumbnail));
      } else if (storyObject.content?.social_image?.imageName) {
        meta.push(...socialImages(storyObject.content.social_image));
      } else {
        meta.push(...socialImages('default'));
      }

      // self referencing canonical
      const link = [];
      const pathUrl = removeTrailingSlash(realPath.replace(/^us\//, ''));
      const trailingSlash = pathUrl !== '' ? '/' : '';
      const canonicalUrl = `${baseUrl}${trailingSlash}${pathUrl}`;

      link.push({
        rel: 'canonical',
        href: canonicalUrl,
      });

      const pageMeta = {
        htmlAttrs: {
          lang: `en-${regionUrl ? regionUrl.toUpperCase() : 'US'}`,
        },
        title: storyObject.content.seo.seoTitle,
        meta,
        link,
        script: [
          {
            type: 'application/ld+json',
            json: this.schema,
          },
        ],
      };

      return pageMeta;
    },
  },
};
