export default {
  getSchema(story, region) {
    return {
      '@type': 'WebPage',
      '@id': 'https://www.deputy.com/#webpage',
      keywords: [story.content.seo.seoKeywords],
      url: 'https://www.deputy.com/' + story.full_slug.replace(/^us\//, ''),
      inLanguage: region ? 'en-' + region.toUpperCase() : 'en',
      name: story.content.seo.seoTitle,
      isPartOf: { '@id': 'https://www.deputy.com/#website' },
      datePublished: story.first_published_at,
      dateModified: story.published_at,
      description: story.content.seo.seoDescription,
      breadcrumb: {
        '@id':
          'https://www.deputy.com/' +
          story.full_slug.replace(/^us\//, '') +
          '#breadcrumb',
      },
    };
  },
};
